<template>
  <div>
    <div class="top-button">
      <button class="btn btn-primary" @click="openDialog = true">
        <i class="fa fa-add"></i>
        Add Line Item
      </button>
    </div>
    <!-- table-responsive -->
    <table class="table" v-loading="loading">
      <thead class="capt-table-head">
        <tr>
          <th>Job Number - Location</th>
          <th>Due Date</th>
          <th>Date Completed</th>
          <th>Type/Notes</th>
          <th>exGST</th>
          <th>GST</th>
          <th>Price</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(record, index) in invoiceJobRecords"
          :key="record.id"
          v-if="invoiceJobRecords.length > 0"
        >
          <td>
            <a :href="getLink(record)">{{
              record.job ? record.job.id + " - " + record.job.name : "N/A"
            }}</a>
          </td>
          <td>
            <a :href="getLink(record)">{{
              record.record && record.record.due_date
                ? moment(record.record.due_date, "YYYY-MM-DD").format("DD/MM/Y")
                : "N/A"
            }}</a>
          </td>
          <td>
            <a :href="getLink(record)">{{
                record.record.latest_taken_at
                ? moment(record.record.latest_taken_at, "YYYY-MM-DD").format("DD/MM/Y")
                : record.completed_at
                ? moment(record.completed_at, "YYYY-MM-DD").format("DD/MM/Y")
                : "N/A"
            }}</a>
          </td>
          <td>
            <a :href="getLink(record)">{{
              record.job ? record.job.notes : record.notes
            }}</a>
          </td>
          <td>
            <a :href="getLink(record)"
              >${{ Number(record.subtotal).toLocaleString("en-AU") }}</a
            >
          </td>
          <td>
            <a :href="getLink(record)"
              >${{ Number(record.tax).toLocaleString("en-AU") }}</a
            >
          </td>
          <td>
            <a :href="getLink(record)"
              >${{ Number(record.total).toLocaleString("en-AU") }}</a
            >
          </td>
          <td>
            <a href="#" @click.prevent="deleteRow(record.id, index)">
              <i class="fa fa-trash text-danger"></i>
            </a>
          </td>
        </tr>

        <tr v-else>
          <td colspan="11">
            You have no invoices yet!
          </td>
        </tr>
      </tbody>
    </table>
    <invoice-line-item-modal
      :invoice="invoice"
      :open.sync="openDialog"
      @added-line-item="newLine"
    ></invoice-line-item-modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    initial_invoice: {
      type: Object,
      required: true,
    },
    initial_invoice_job_records: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      invoiceJobRecords: this.initial_invoice_job_records,
      invoice: this.initial_invoice,
      openDialog: false,
      loading: false,
    };
  },
  methods: {
    newLine(data) {
      window.location.reload();
    },
    getLink(row) {
      if (row.job) return `/jobs/${row.job.id}`;
      else return null;
    },
    async deleteRow(id, index) {
      try {
        this.loading = true;
        const res = await axios.delete(
          `/v2/invoices/${this.invoice.id}/invoice_job_records/${id}`
        );
        this.$message({
          message: "Invoice line item removed",
          type: "success",
        });
        this.invoiceJobRecords.splice(index, 1);
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
        this.$message({
          message: "Invoice line item cannot be removed",
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.top-button
    margin-bottom: 10px
    margin-top: 10px
    display: flex
    align-items: center
    justify-content: flex-end
</style>
